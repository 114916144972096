import styled, { css } from 'styled-components';

import { colors, cssBreakpoints } from 'theme/theme';

export const CmsSplitContentBlockFullWidthVideoBackgroundHolder = styled.div`
  display: contents;

  @media all and (max-width: ${cssBreakpoints.smDown}) {
    display: none;
  }

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    & + * {
      pointer-events: none;
    }
  }
`;

export const CmsSplitContentBlockFullWidthVideoHolder = styled.div`
  display: contents;

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    display: none;
  }
`;

export const CmsSplitContentBlockFullWidthVideoPlaceholder = styled.div`
  width: 50%;

  @media all and (max-width: ${cssBreakpoints.smDown}) {
    display: none;
  }
`;

export const CmsSplitContentBlockMovementCounterDigitContainer = styled.div`
  display: inline-block;
  height: 42px;
  width: 34px;
  background: ${colors.brandGreen};
  border-radius: 3px;
  text-align: center;
  line-height: 42px;
  margin-right: 3px;
  position: relative;
  overflow: hidden;
`;

export const CmsSplitContentBlockMovementCounterDigitSlot = styled.div<{
  $number: number;
}>`
  display: flex;
  flex-direction: column;
  width: 34px;
  position: absolute;
  left: 0;
  ${({ $number }) => css`
    top: -${$number * 42}px;
  `}
  transition: top 1s ease;
`;

export const CmsSplitContentBlockMovementCounterDigit = styled.div`
  font-size: 32px;
  font-weight: 400;
  color: white;
`;

export const CmsSplitContentBlockMovementPeopleJoined = styled.div`
  display: inline-block;
  font-style: italic;
  color: ${colors.mediumContentGrey};
`;
